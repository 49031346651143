(function (win, dom) {

	/**
	 * 鍙傛暟锛歕r
	 * o [object]
	 * o.selId -> 婊氬姩鍐呭鐩掑瓙鐨刬d 锛堝繀椤伙級
	 * o.width -> 婊氬姩鏉＄殑瀹藉害 锛堥粯璁?0锛岃璁剧疆涓哄伓鏁?
	 * o.bgColor -> 婊氬姩鏉″寘瑁瑰眰鐨勯鑹 (榛樿#eaeaea)
	 * o.barColor -> 婊氬姩鏉＄殑棰滆壊 (榛樿#ccc)
	 * o.enterShow -> 鏄惁涓洪紶鏍囪繘鍏ュ寘瑁瑰眰鍚庢樉绀烘粴鍔ㄦ潯 锛堥粯璁rue鏄級
	 * o.hasY -> 鏄惁闇€瑕乊杞存粴鍔ㄦ潯锛堥粯璁rue闇€瑕侊級
	 * o.hasX -> 鏄惁闇€瑕乆杞存粴鍔ㄦ潯锛堥粯璁alse涓嶉渶瑕侊級
	 * o.borderRadius -> 婊氬叕鏉″渾寮х殑瀹藉害锛堥粯璁や负o.width鐨勪竴鍗婏級
	 * 娉細閫氳繃璋冪敤MyScrollBar.setSize()鍑芥暟鍙互閲嶇疆婊氬姩鏉￠珮搴r
	 */
	function MyScrollBar (o) {
		this.init(o);
	}

	MyScrollBar.prototype.init = function (o) {
		this.bXBar = false;						// 鏄惁鏈墄杞存粴鍔ㄦ潯
		this.bYBar = false;						// 鏄惁鏈墆杞存粴鍔ㄦ潯
					
		this.iScrollTop = 0;					// 婊氬姩鍐呭鐨剏杞存粴鍔ㄨ窛绂籠r
		this.iScrollLeft = 0;					// 婊氬姩鍐呭鐨剎杞存粴鍔ㄨ窛绂籠r

		this.bYShow = false;					// y杞存粴鍔ㄦ潯鏄剧ず涓庡惁
		this.bXShow = false;					// x杞存粴鍔ㄦ潯鏄剧ず涓庡惁

		this.oWrapper = dom.getElementById(o.selId);		// 婊氬姩鐩掑瓙
		this.oScroll = this.oWrapper.firstElementChild;		// 婊氬姩鍐呭

		this.setParam(o);									// 璋冪敤璁剧疆鍒濆鍙傛暟鍠奬r
		this.addScrollBar();								// 璋冪敤娣诲姞婊氬姩鏉″嚱鏁癨r

		this.initState();									// 璋冪敤璁剧疆鍒濆鐘舵€佸嚱鏁癨r
		this.initEvent();									// 璋冪敤璁剧疆浜嬩欢鍑芥暟
	}

	
	// 鍒濆鍖栫姸鎬乗r
	MyScrollBar.prototype.initState = function () {
		// 缁欏寘瑁瑰眰璁剧疆榛樿瀹氫綅
		var sWPosition = getStyle(this.oWrapper, 'position');
		if(sWPosition == 'static') {
			setStyle(this.oWrapper, {
				position: 'relative'
			})
		}

		setStyle(this.oScroll, {
			position: 'relative'
		})

		if ( this.bYBar ) {

			setStyle(this.oYBox, {
				display: this.enterShow ? 'none' : 'block',			// 濡傛灉enterShow涓簍rue灏辨槸闇€瑕佽繘鍏ュ寘瑁瑰眰鎵嶆樉绀烘粴鍔ㄦ潯
				position: 'absolute',
				top: 0,
				right: 0,
				zIndex: 10,
				width: this.width + 'px',
				height: '100%',
				backgroundColor: this.bgColor
			});

			setStyle(this.oYBar, {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				backgroundColor: this.barColor,
				borderRadius: this.borderRadius + 'px',
				transition: 'all ' + this.time + 'ms'
			})
		}

		if ( this.bXBar ) {

			setStyle(this.oXBox, {
				display: this.enterShow ? 'none' : 'block',			// 濡傛灉enterShow涓簍rue灏辨槸闇€瑕佽繘鍏ュ寘瑁瑰眰鎵嶆樉绀烘粴鍔ㄦ潯
				position: 'absolute',
				bottom: 0,
				left: 0,
				zIndex: 10,
				height: this.width + 'px',
				width: '100%',
				backgroundColor: this.bgColor
			})

			setStyle(this.oXBar, {
				position: 'absolute',
				bottom: 0,
				left: 0,
				height: '100%',
				backgroundColor: this.barColor,
				borderRadius: this.borderRadius + 'px',
				transition: 'all ' + this.time + 'ms'
			})
		}

		this.setSize();		// 璁剧疆婊氬姩鏉＄殑瀹介珮
	}

	// 鍒濆鍖栦簨浠禱r
	MyScrollBar.prototype.initEvent = function () {
		var _this = this;

		// 榧犳爣鍦ㄥ寘瑁瑰眰涓劧鍚庢粴杞翠笂涓嬫粴鍔╘r
		var sUserAgent = win.navigator.userAgent.toLowerCase();
		if ( sUserAgent.indexOf('firefox') != -1 ) {
			// 鐏嫄娴忚鍣ㄦ粴杞存粴鍔╘r
			this.oWrapper.addEventListener('DOMMouseScroll', function (e) {
				if ( _this.bYBar && _this.bYShow ) {
					e.preventDefault();

					_this.iScrollTop += e.detail > 0 ? 60 : -60;
					_this.iScrollTop = _this.iScrollTop <= 0 ? 0 : _this.iScrollTop >= _this.iScrollH - _this.iWrapperH ? _this.iScrollH - _this.iWrapperH : _this.iScrollTop;
					_this.setTransLate();
					_this.setYTop(_this.iScrollTop / _this.iScrollH * _this.iYBoxH);
				}
			})
		} else {
			// 璋锋瓕銆乮e婊氳酱婊氬姩
			this.oWrapper.onmousewheel = function (evt) {
				if ( _this.bYBar && _this.bYShow ) {
					var e = evt || win.event;
					evt ? e.preventDefault() : e.returnValue = false;

					_this.iScrollTop += e.wheelDelta < 0 ? 60 : -60;
					_this.iScrollTop = _this.iScrollTop <= 0 ? 0 : _this.iScrollTop >= _this.iScrollH - _this.iWrapperH ? _this.iScrollH - _this.iWrapperH : _this.iScrollTop;
					_this.setTransLate();
					_this.setYTop(_this.iScrollTop / _this.iScrollH * _this.iYBoxH);
				}
			}
		}

		// 杈撳叆琛ㄧЩ鍏ュ寘瑁瑰眰鏄剧ず婊氬姩鏉°€佺Щ鍑洪殣钘忔粴鍔ㄦ潯
		var isInWrapper = false;
		this.oWrapper.onmouseenter = function () {
			isInWrapper = true;
			if ( _this.enterShow ) {
				if ( _this.bYBar && _this.bYShow ) {
					setStyle(_this.oYBox, {
						display: 'block'
					})
				}
				if ( _this.bXBar && _this.bXShow ) {
					setStyle(_this.oXBox, {
						display: 'block'
					})
				}
			}
		}
		this.oWrapper.onmouseleave = function () {
			isInWrapper = false;
			if ( _this.enterShow ) {
				if ( _this.bYBar && !bYDown && _this.bYShow ) {
					setStyle(_this.oYBox, {
						display: 'none'
					})
				}
				if ( _this.bXBar && !bXDown && _this.bXShow ) {
					setStyle(_this.oXBox, {
						display: 'none'
					})
				}
			}
		}


		// 榧犳爣鍦ㄦ粴鍔ㄦ潯涓婃寜涓嬶紝鎯宠鎷栧姩
		var bYDown = false, bXDown = false;		// 鍦ㄦ粴鍔ㄦ潯涓婃槸鍚︽寜涓媆r
		var bYLeave = true, bXLeave = true;		// 鏄惁绂诲紑
		var iDownPageY = 0, iDownPageX = 0;		// 鎸変笅鏃堕紶鏍囧埌椤甸潰椤堕儴鐨勮窛绂籠r
		var iYBarTop = 0, iXBarLeft = 0;		// 鎸変笅鏃舵粴鍔ㄦ潯鐨則op/left
		if ( this.bYBar ) {
			// 榧犳爣绉讳笂Y杞存粴鍔ㄦ潯鍙樿壊
			if ( this.enterColor ) {
				this.oYBar.onmouseenter = function () {
					bYLeave = false;
					setStyle(this, {
						backgroundColor: _this.enterColor
					})
				}
				this.oYBar.onmouseleave = function () {
					bYLeave = true;
					if ( !bYDown ) {
						setStyle(this, {
							backgroundColor: _this.barColor
						})
					}
				}
			}
				
			// 榧犳爣鍦╕杞存粴鍔ㄦ潯鎸変笅
			this.oYBar.onmousedown = function (e) {
				if ( _this.bYShow ) {
					bYDown = true;
					iDownPageY = e.clientY + dom.documentElement.scrollTop || dom.body.scrollTop;
					iYBarTop = parseInt(getStyle(this, 'top'));

					// 璁剧疆杩囧害鏃堕棿涓?
					_this.setYTime(0);
					// 绂佹鏂囨湰鍙€変腑
					canSelectText(false);
				}
			}

			// 榧犳爣鍦ㄦ寜涓媃杞存粴鍔ㄦ潯鍚庢姮璧穃r
			dom.addEventListener('mouseup', function () {
				if ( bYDown && _this.bYShow ) {
					bYDown = false;

					// 鍥炲杩囧害鏃堕棿
					_this.setYTime(_this.time);
					// 鎭㈠鏂囨湰鍙€変腑
					canSelectText(true);

	    			if ( !isInWrapper && _this.enterShow ) {
	    				setStyle(_this.oYBox, {
	    					display: 'none'
	    				})
	    			}
				}
				if ( !bYDown && bYLeave ) {
					setStyle(_this.oYBar, {
						backgroundColor: _this.barColor
					})
				}
			})

			// 榧犳爣鎸変笅Y杞存粴鍔ㄦ潯鍚庢嫋鍔╘r
			dom.addEventListener('mousemove', function (e) {
				if ( bYDown && _this.bYShow ) {
					var iNowPageY = e.clientY + dom.documentElement.scrollTop || dom.body.scrollTop;
					var iNowTop = iYBarTop + iNowPageY - iDownPageY;

					iNowTop = iNowTop <= 0 ? 0 : iNowTop >= _this.iYBoxH - _this.iYBarH ? _this.iYBoxH - _this.iYBarH : iNowTop;
					_this.iScrollTop = iNowTop / _this.iYBoxH * _this.iScrollH;
					_this.setTransLate();
					_this.setYTop(iNowTop);
				}
			})

			// 绂佹榛樿鎷栧姩浜嬩欢
			this.oYBar.ondrag = function (e) {
				var e = evt || win.event;
				evt ? e.preventDefault() : e.returnValue = false;
			}
		}

		if ( this.bXBar ) {
			// 榧犳爣绉讳笂Y杞存粴鍔ㄦ潯鍙樿壊
			if ( this.enterColor ) {
				this.oXBar.onmouseenter = function () {
					bXLeave = false;
					setStyle(this, {
						backgroundColor: _this.enterColor
					})
				}
				this.oXBar.onmouseleave = function () {
					bXLeave = true;
					if ( !bXDown ) {
						setStyle(this, {
							backgroundColor: _this.barColor
						})
					}
				}
			}

			// 榧犳爣鍦╔杞存粴鍔ㄦ潯鎸変笅
			this.oXBar.onmousedown = function (e) {
				if ( _this.bXShow ) {
					bXDown = true;
					iDownPageX = e.clientX + dom.documentElement.scrollLeft || dom.body.scrollLeft;
					iXBarLeft = parseInt(getStyle(this, 'left'));

					// 璁剧疆杩囧害鏃堕棿涓?
					_this.setXTime(0);
					// 绂佹鏂囨湰鍙€変腑
	    			canSelectText(false);
				}
			}

			// 榧犳爣鍦ㄦ寜涓媂杞存粴鍔ㄦ潯鍚庢姮璧穃r
			dom.addEventListener('mouseup', function () {
				if ( bXDown && _this.bXShow ) {
					bXDown = false;

					// 鍥炲杩囧害鏃堕棿
					_this.setXTime(_this.time);
					// 鎭㈠鏂囨湰鍙€変腑
	    			canSelectText(true);

	    			if ( !isInWrapper && _this.enterShow ) {
	    				setStyle(_this.oXBox, {
	    					display: 'none'
	    				})
	    			}
	    		}

	    		if ( !bXDown && bXLeave ) {
	    			setStyle(_this.oXBar, {
	    				backgroundColor: _this.barColor
	    			})
	    		}
			})

			// 榧犳爣鎸変笅X杞存粴鍔ㄦ潯鍚庢嫋鍔╘r
			dom.addEventListener('mousemove', function (e) {
				if ( bXDown && _this.bXShow ) {
					var iNowPageX = e.clientX + dom.documentElement.scrollLeft || dom.body.scrollLeft;
					var iNowLeft = iXBarLeft + iNowPageX - iDownPageX;

					iNowLeft = iNowLeft <= 0 ? 0 : iNowLeft >= _this.iXBoxW - _this.iXBarW ? _this.iXBoxW - _this.iXBarW : iNowLeft;
					_this.iScrollLeft = iNowLeft / _this.iXBoxW * _this.iScrollW;
					_this.setTransLate();
					_this.setXLeft(iNowLeft);
				}
			})

			// 绂佹榛樿鎷栧姩浜嬩欢
			this.oXBar.ondrag = function (e) {
				var e = evt || win.event;
				evt ? e.preventDefault() : e.returnValue = false;
			}
		}	
	}

	// 璁剧疆榛樿鍙傛暟
	MyScrollBar.prototype.setParam = function (o) {
		this.width = o.width ? o.width : 10;					// 婊氬姩鏉＄殑瀹藉害

		this.bgColor = o.bgColor ? o.bgColor : '#eaeaea';		// 婊氬姩鏉¤儗鏅鑹瞈r

		this.barColor = o.barColor ? o.barColor : '#ccc';		// 婊氬姩鏉￠鑹瞈r

		this.enterColor = o.enterColor || false;			// 榧犳爣绉讳笂婊氬姩鏉℃椂鐨勯鑹瞈r

		this.enterShow = o.enterShow === false ? false : true;	// 鏄惁杩涘叆鍖呰９灞傚湪鏄剧ず婊氬姩鏉r

		this.hasY = o.hasY === false ? false : true;			// 鏄惁鏈塝杞存粴鍔ㄦ潯

		this.hasX = o.hasX === true ? true : false;				// 鏄惁鏈塜杞存粴鍔ㄦ潯

		this.borderRadius = o.borderRadius >= 0 ? o.borderRadius : this.width / 2;	// 鍦嗚

		this.time = o.time || 0;
	}

	// 鍒ゆ柇鏄惁娣诲姞XY杞存粴鍔ㄦ潯
	MyScrollBar.prototype.addScrollBar = function () {
		// 鑾峰彇鍖呰９灞備笌婊氬姩灞傜殑灏哄
		this.getSize();

		if ( this.hasX ) {
			this.bXBar = true;
			
			this.oXBox = dom.createElement('div');					// X杞存粴鍔ㄦ潯鐩掑瓙
			this.oXBar = dom.createElement('div');					// X杞存粴鍔ㄦ潯

			this.oXBox.appendChild(this.oXBar);						// 婊氬姩鏉℃彃鍏ユ粴鍔ㄦ潯鐩掑瓙
			this.oWrapper.insertBefore(this.oXBox, this.oScroll);	// 婊氬姩鏉＄洅瀛愭彃鍒皁Scroll涔嬪墠
		}

		if ( this.hasY ) {
			this.bYBar = true;
			
			this.oYBox = dom.createElement('div');					// X杞存粴鍔ㄦ潯鐩掑瓙
			this.oYBar = dom.createElement('div');					// X杞存粴鍔ㄦ潯

			this.oYBox.appendChild(this.oYBar);						// 婊氬姩鏉℃彃鍏ユ粴鍔ㄦ潯鐩掑瓙
			this.oWrapper.insertBefore(this.oYBox, this.oScroll);	// 婊氬姩鏉＄洅瀛愭彃鍒皁Scroll涔嬪墠
		}
	}

	// 鏇存柊/鑾峰彇鍖呰９灞備笌婊氬姩灞傜殑灏哄
	MyScrollBar.prototype.getSize = function () {
		var oWrapperSize = getClientSize(this.oWrapper);
		var oScrollSize = getClientSize(this.oScroll);

		this.iWrapperClientW = oWrapperSize.width;
		this.iWrapperClientH = oWrapperSize.height;

		this.iPaddingT = parseInt(getStyle(this.oWrapper, 'paddingTop'));
		this.iPaddingR = parseInt(getStyle(this.oWrapper, 'paddingRight'));
		this.iPaddingB = parseInt(getStyle(this.oWrapper, 'paddingBottom'));
		this.iPaddingL = parseInt(getStyle(this.oWrapper, 'paddingLeft'));

		this.iWrapperW = oWrapperSize.width - this.iPaddingR - this.iPaddingL;
		this.iWrapperH = oWrapperSize.height - this.iPaddingT - this.iPaddingB;
		this.iScrollW = oScrollSize.width;
		this.iScrollH = oScrollSize.height;

		if ( this.bYBar ) {
			this.iYBoxH = oWrapperSize.height;
			this.iYBarH = this.iWrapperH / this.iScrollH * this.iYBoxH;
		}

		if ( this.bXBar ) {
			this.iXBoxW = oWrapperSize.width;
			this.iXBarW = this.iWrapperW /this.iScrollW * this.iXBoxW;
		}
	}

	// 璁剧疆灏哄
	MyScrollBar.prototype.setSize = function (time) {
		var _this = this;

		// 淇敼dom鑺傜偣鍙兘鏈夊欢杩焅r
		time = time || 100;
		setTimeout(function () {
			// 鏇存柊鍖呰９灞備笌婊氬姩灞傜殑灏哄
			_this.getSize();

			// 瓒呭嚭鏈€澶ф粴鍔ㄩ珮搴r
			if ( _this.iScrollTop >= _this.iScrollH - _this.iWrapperH ) {
				_this.iScrollTop = _this.iScrollH - _this.iWrapperH;
			}
			if ( _this.iScrollW >= _this.iScrollW - _this.iWrapperW ) {
				_this.iScrollLeft = _this.iScrollW - _this.iWrapperW;
			}
			_this.setTransLate();

			// 婊氬姩鏉r
			if ( _this.bYBar ) {
				if ( _this.iWrapperH >= _this.iScrollH ) {
					setStyle(_this.oYBox, {
						display: 'none'
					})
					_this.bYShow = false;
				} else {
					if ( !_this.enterShow ) {
						setStyle(_this.oYBox, {
							display: 'block'
						})
					}
					setStyle(_this.oYBar, {
						height: _this.iYBarH + 'px',
						top: _this.iScrollTop / _this.iScrollH * _this.iYBoxH + 'px'
					}, 0)
					_this.bYShow = true;
				}
			}

			if ( _this.bXBar ) {
				if ( _this.iWrapperW >= _this.iScrollW ) {
					setStyle(_this.oXBox, {
						display: 'none'
					})
					_this.bXShow = false;
				} else {
					if ( !_this.enterShow ) {
						setStyle(_this.oXBox, {
							display: 'block'
						})
					}
					setStyle(_this.oXBar, {
						width: _this.iXBarW + 'px',
						left: _this.iScrollLeft / _this.iScrollW * _this.iYBoxW + 'px'
					}, 0)
					_this.bXShow = true;
				}
			}
		}, time);
	}

	/**
	 * 浣滅敤锛氳缃畂Scroll鐨勪綅缃浆鎹ransform:translate
	 * iTime -> 鍔ㄧ敾鏃堕棿锛屽ぇ浜?鏈夋晥锛屽惁鍒欐棤鍔ㄧ敾锛屾绉抃r
	 */ 
	MyScrollBar.prototype.setTransLate = function (iTime) {
		var sTranslate = 'translate(-' + this.iScrollLeft + 'px, -' + this.iScrollTop + 'px)';
		setStyle(this.oScroll, {
			transition: 'all ' + (iTime >= 0 ? iTime : this.time) + 'ms',
			transform: sTranslate,
			msTransform: sTranslate,
			mozTransform: sTranslate,
			webkitTransform: sTranslate,
			oTransform: sTranslate
		})
	}

	// 璁剧疆Y杞磋繃搴︽椂闂碶r
	MyScrollBar.prototype.setYTime = function (iTime) {
		setStyle(this.oYBar, {
			transition: 'all ' + (iTime >= 0 ? iTime : this.time) + 'ms'
		})
	}

	// 璁剧疆婊氬姩top
	MyScrollBar.prototype.setYTop = function (iTop) {
		setStyle(this.oYBar, {
			top: iTop + 'px'
		})
	}

	// 璁剧疆X杞磋繃搴︽椂闂碶r
	MyScrollBar.prototype.setXTime = function (iTime) {
		setStyle(this.oXBar, {
			transition: 'all ' + (iTime >= 0 ? iTime : this.time) + 'ms'
		})
	}

	// 璁剧疆婊氬姩left
	MyScrollBar.prototype.setXLeft = function (iLeft, iTime) {
		setStyle(this.oXBar, {
			transition: 'all ' + (iTime >= 0 ? iTime : this.time) + 'ms',
			left: iLeft + 'px'
		})
	}

	/**
	 * 浣滅敤锛氳烦鍒版寚瀹氫綅缃甛r
	 * o.id -> 瑕佽烦鍒伴偅涓猧d鐨勪綅缃紱
	 * o.pos -> 瑕佽烦鍒伴偅涓叿浣撶殑浣嶇疆锛屽鏋滀负瀛楃涓诧紙涓や釜閫変腑鎷?top','bottom'锛?left'锛?right')锛孿r
	 濡傛灉涓哄璞★紙{top: number, left: number}锛夛紝涓哄璞℃椂濡傛灉瑕乊杞存粴鍔ㄦ潯婊氬姩灏辫缃畉op锛岃涓や釜杞翠竴璧锋粴鍔ㄦ墠涓€璧疯缃€俓r
	 * o.time -> 鍔ㄧ敾鏃堕棿锛屼笉璁炬病鏈夊姩鐢籠r
	 * 娉細濡傛灉id瀛樺湪锛屽垯pos涓嶇敓鏁圽r
	 */
	MyScrollBar.prototype.jump = function (o) {
		o = o || {};
		var oPos = {top: 0, left: 0};
		var iTop = 0;
		var iBottome = this.iScrollH - this.iWrapperClientH + this.iPaddingT + this.iPaddingB > 0 ? this.iScrollH - this.iWrapperClientH + this.iPaddingT + this.iPaddingB : 0;
		var iLeft = 0;
		var iRight = this.iScrollW - this.iWrapperClientW + this.iPaddingL + this.iPaddingR > 0 ? this.iScrollW - this.iWrapperClientW + this.iPaddingL + this.iPaddingR : 0;

		if ( o.id ) {
			var obj = document.getElementById(o.id);
			oPos = getPosition(obj, this.oScroll);
			
			if ( this.bYBar ) {
				oPos.top += this.iPaddingT;
			}

			if ( this.bXBar ) {
				oPos.left += this.iPaddingL;
			}
		} else if ( o.pos ) {
			if ( typeof o.pos == 'string' ) {
				switch(o.pos) {
					case 'top': oPos.top = iTop; break;
					case 'bottom': oPos.top = iBottome; break;
					case 'left': oPos.left = iLeft; break;
					case 'right': oPos.left = iRight; break;
					default: break;
				}
			} else if ( typeof o.pos == 'object' ) {
				oPos = o.pos;
			}
		}

		oPos.top = oPos.top > iBottome ? iBottome : oPos.top >= 0 ? oPos.top : 0;
		oPos.left = oPos.left > iRight ? iRight : oPos.left >= 0 ? oPos.left : 0;

		this.iScrollTop = oPos.top;
		this.iScrollLeft = oPos.left;
		this.setTransLate(o.time);
		if ( this.bYBar ) {
			this.setYTime(o.time);
			this.setYTop(this.iScrollTop / this.iScrollH * this.iYBoxH);
		}
		if ( this.bXBar ) {
			this.setXTime(o.time);
			this.setXLeft(this.iScrollLeft / this.iScrollW * this.iXBoxW, o.time);
		}
	}

	// 鑾峰彇鏍峰紡
	function getStyle (obj, name) {
		if(win.getComputedStyle) {
			return getComputedStyle(obj, null)[name];
		} else {
			return obj.currentStyle[name];
		}
	}

	// 璁剧疆鏍峰紡
	function setStyle (obj, oStyle) {
        for(var i in oStyle) {
            obj.style[i] = oStyle[i];
        }
    }

	/**
	 * 浣滅敤锛氳幏鍙栧璞＄殑offset锛堝唴瀹瑰昂瀵?padding+border锛夊昂瀵革紝display:none;鍏冪礌涔熷彲浠ヨ幏鍙朶r
	 * 鍙傛暟锛歰bj -> 瑕佽幏鍙栧昂瀵哥殑鍏冪礌
	 * 杩斿洖锛歳es -> width 瀹见 res -> height 楂榎r
	 * 渚濊禆锛歡etStyle锛宻etStyle
	 */
	function getOffsetSize (obj) {
	    var sDisplay = getStyle(obj, "display");
	    var res = {}

	    if ( sDisplay != "none" ) {
	        res.width = obj.offsetWidth;
	        res.height = obj.offsetHeight;
	    } else {
	        var oldStyle = {
	            position: getStyle(obj, "position"),
	            visibility: getStyle(obj, "visibility"),
	            display: sDisplay
	        }
	        var newStyle = {
	            position: "absolute",
	            visibility: "hidden",
	            display: "inline-block"
	        }
	        setStyle(obj, newStyle);
	        res.width = obj.offsetWidth;
	        res.height = obj.offsetHeight;
	        setStyle(obj, oldStyle);
	    }
	    return res;
	}

	// 璁＄畻瀹為檯鍐呭+padding瀹介珮鍗砪lientWidth/clientHeight锛屼絾ie鏃禼lient鍖呭惈杈规
	function getClientSize (obj) {
		var iTopW = parseInt(getStyle(obj, 'borderTopWidth'));
		var iRightW = parseInt(getStyle(obj, 'borderRightWidth'));
		var iBottomW = parseInt(getStyle(obj, 'borderBottomWidth'));
		var iLeftW = parseInt(getStyle(obj, 'borderLeftWidth'));

		var oOffset = getOffsetSize(obj);
		return {
			width: oOffset.width <= 0 ? oOffset.width : oOffset.width - iLeftW - iRightW,
			height: oOffset.height <= 0 ? oOffset.height : oOffset.height - iTopW - iBottomW
		}
	}

	// 绂佹涓庢仮澶嶆枃鏈彲閫変腑,true涓哄彲閫変腑锛宖alse涓轰笉鍙€変腑
	function canSelectText (bCan) {
		if ( !bCan ) {
			dom.body.style.mozUserSelect = 'none';
			dom.body.style.webkitUserSelect = 'none';
			dom.body.style.msUserSelect = 'none';
			dom.body.style.khtmlUserSelect = 'none';
			dom.body.style.userSelect = 'none';
		} else {
			dom.body.style.mozUserSelect = 'text';
			dom.body.style.webkitUserSelect = 'text';
			dom.body.style.msUserSelect = 'text';
			dom.body.style.khtmlUserSelect = 'text';
			dom.body.style.userSelect = 'text';
		}
	}

	/**
	 * 浣滅敤锛氳幏鍙杘bj鍒扮洰鏍噂oal鐨刾osition璺濈
	 */
	function getPosition (obj, goal) {
		var oPos = {
			top: obj.offsetTop,
			left: obj.offsetLeft
		}

		if ( obj.parentNode != goal ) {
			var obj = getPosition(obj.parentNode, goal);
			oPos.top += obj.top;
			oPos.left += obj.left;
		} else {
			return oPos;
		}
	}

	if ( typeof define === "function" && define.amd ) {
		define([], function () {
			return MyScrollBar;
		});
	}
	win.MyScrollBar = MyScrollBar;
})(window, document);