import './index.scss'


// 鐐瑰嚮鎵撳紑绉诲姩绔鑸爮
$('.root-header [data-open="cmenu"]').on('click', function () {
  $('.root-header .nav-mob').addClass('active')
})
// 鐐瑰嚮鍏抽棴绉诲姩绔鑸爮
$('.root-header .nav-mob .nav-mob-close').on('click', function () {
  $('.root-header .nav-mob').removeClass('active')
  $('.root-header .nav-mob ul li').removeClass('active')
  $('.root-header .nav-mob ul li .sub-slide').slideUp(400)
})
//鐐瑰嚮绉诲姩绔鑸垪琛ㄥ睍寮€瀛愰」
$('.root-header .nav-mob ul li').on('click', function () {
  if (!$(this).hasClass('active')) {
    $(this).addClass('active').siblings().removeClass('active')
    $(this).find('.sub-slide').slideDown(400)
    $(this).siblings().find('.sub-slide').slideUp(400)
  }
})

//鐢佃剳绔鑸姸鎬佹敼鍙橈紙榧犳爣缁忚繃鏃讹級
$(".root-header .header-nav ul li").mouseenter(function () {
  if (!$(this).hasClass('active')) {
    $(this).addClass('hover');
    $(this).siblings('.active').addClass('not-active');
  }
});
$(".root-header .header-nav ul li").mouseleave(function () {
  if (!$(this).hasClass('active')) {
    $('.root-header .header-nav ul li').removeClass('hover');
    $('.root-header .header-nav ul li.active').removeClass('not-active');
  }
});

//鐐瑰嚮鎼滅储鍥炬爣锛屾樉绀烘悳绱㈠尯鍩焅r
$('.header-search .search-icon').on('click',function () {
  if($(this).hasClass('active')){
    $(this).removeClass('active')
    $('.root-header').removeClass('search-header')
    $('.search-cont').removeClass('active')
  }else{
    $('.root-header').addClass('search-header')
    $(this).addClass('active')
    $('.search-cont').addClass('active')
  }
})
// $(".root-header").mouseleave(function () {
//   setTimeout(() => {
//     $('.header-search .search-icon').removeClass('active')
//     $('.search-cont').removeClass('active')
//     $('.root-header').removeClass('search-header')
//   }, 1000);
// });